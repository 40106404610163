<template>
  <el-container class="setting-caution pad-lr-20">
    <div v-if="yjDrawer" class="yq-height w-800">
      <!-- <vue-scroll :ops="{bar: {keepShow: true}}"> -->
        <p class="yjts">
          <span class="mar-r-10" :class="plan.notify ? 'cor-blue' : ''">预警推送</span>
          <el-switch
            v-model="plan.notify"
            inactive-color="#D2D2D2"
            :active-value="1"
            :inactive-value="0"
            @change="changeState(plan)">
          </el-switch>
        </p>
        <div v-if="plan.notify === 1">
            <div class="mod-title">预警时间</div>
            <div class="hr"></div>
            <el-form ref="form" label-width="100px" class="form-box">
              <el-form-item label="监控时间">
                <el-radio-group v-model="plan.condition.period.value">
                    <el-radio label="presently">实时预警</el-radio><br>
                    <el-radio label="frequency">循环预警</el-radio>
                    <el-select v-model="plan.condition.period.frequency" class="w-120">
                      <el-option label="每1小时" :value="60"></el-option>
                      <el-option label="每2小时" :value="120"></el-option>
                      <el-option label="每4小时" :value="240"></el-option>
                      <el-option label="每8小时" :value="480"></el-option>
                    </el-select>
                    <br>
                    <el-radio label="timer">定时预警</el-radio>
                    <el-select v-model="timer_setting.num" placeholder="每日一次" class="w-120 mar-r-10">
                      <el-option label="每日一次" :value="1"></el-option>
                      <el-option label="每日两次" :value="2"></el-option>
                    </el-select>
                    <el-select class="w-120 mar-r-10"
                      v-if="timer_setting.num >= 1"
                      v-model="timer_setting.one">
                      <el-option :value="hourFormat(index, 'second')"
                        v-for="(h, index) in 24"
                        :key="index"
                        :label="hourFormat(index, 'hour')"
                        :disabled="timer_setting.num >= 1 && timer_setting.two === hourFormat(index, 'second')">
                      </el-option>
                    </el-select>
                    <el-select class="w-120"
                      v-if="timer_setting.num >= 2"
                      v-model="timer_setting.two">
                      <el-option :value="hourFormat(index, 'second')"
                        v-for="(h, index) in 24"
                        :key="index"
                        :label="hourFormat(index, 'hour')"
                        :disabled="timer_setting.one === hourFormat(index, 'second')">
                      </el-option>
                    </el-select>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="免打扰">
                <el-checkbox-group v-model="plan.condition.mute.value" @change="handleTimerangeChange(plan.condition.mute)">
                    <el-checkbox label="push_night">夜间不预警</el-checkbox>
                    <el-select v-model="plan.condition.mute.push_night[0]" placeholder="请选择" class="w-120 mar-r-10">
                        <el-option
                                v-for="item in timeRange"
                                :key="item"
                                :label="item"
                                :value="item">
                        </el-option>
                    </el-select>
                    <span class="mar-r-10">至</span>
                    <el-select v-model="plan.condition.mute.push_night[1]" placeholder="08：00" class="w-120">
                        <el-option
                                v-for="item in timeRange"
                                :key="item"
                                :label="item"
                                :value="item">
                        </el-option>
                    </el-select>
                    <br>
                    <el-checkbox label="push_week">周末不预警</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-form>
            <div class="mod-title">推送方式</div>
            <div class="hr"></div>
            <el-form ref="form" label-width="100px" class="form-box">
              <el-form-item label="推送方式">
                <el-checkbox-group v-model="plan.condition.terminal.value">
                  <el-checkbox label="web">PC端</el-checkbox>
                  <el-select
                    v-model="plan.condition.terminal.web"
                    multiple
                    collapse-tags
                    :placeholder="plan.condition.terminal.value.indexOf('web') !== -1 ? '请选择账号' : '请选择账号'"
                    class="w-200">
                    <el-option
                      v-for="(user, index) in users"
                      :key="index"
                      :label="user.username"
                      :value="user.id">
                    </el-option>
                  </el-select>
                  <span class="err-text" v-show="errMsg.web">请选择联系人</span>
                  <br>
                  <el-checkbox label="wechat">微信</el-checkbox>
                  <el-select
                    v-model="plan.condition.terminal.wechat"
                    multiple
                    @change="wechatContactChange(plan)"
                    collapse-tags
                    :placeholder="plan.condition.terminal.value.indexOf('wechat') !== -1 ? '从通讯录勾选' : '从通讯录勾选'"
                    class="w-200">
                    <el-option value="new_contact">
                      <template>
                        <i class="iconfont icon-zengjia f-l" />
                        <span>新增联系人</span>
                      </template>
                    </el-option>
                    <el-option
                      v-for="(wechat, index) in contacts.wechat"
                      :key="index"
                      :label="wechat.name"
                      :value="wechat.id" />
                  </el-select>
                  <span class="err-text" v-show="errMsg.wechat">请选择联系人</span>
                  <br>
                  <el-checkbox label="email" :disabled="plan.condition.period.value == 'presently'">邮件</el-checkbox>
                  <el-select
                    v-model="plan.condition.terminal.email"
                    @change="emailContactChange(plan)"
                    @visible-change="loadContacts"
                    :disabled="plan.condition.period.value == 'presently'"
                    multiple
                    collapse-tags
                    :placeholder="plan.condition.terminal.value.indexOf('email') !== -1 ? '从通讯录勾选' : '从通讯录勾选'"
                    class="w-200">
                      <el-option value="new_contact">
                        <template>
                          <i class="iconfont icon-zengjia f-l" />
                          <span>新增联系人</span>
                        </template>
                      </el-option>
                      <el-option
                        v-for="(contact, index) in contacts.email"
                        :label="contact.name"
                        :value="contact.id"
                        :key="index">
                      </el-option>
                  </el-select>
                  <span class="err-text" v-show="errMsg.email">请选择联系人</span>
                </el-checkbox-group>
              </el-form-item>
            </el-form>
            <div class="mod-title">消息筛选</div>
            <div class="hr"></div>
            <el-form ref="form" label-width="100px" class="form-box">
              <el-form-item label="情感属性">
                <el-checkbox v-model="plan.condition.term.emotion.value">仅敏感</el-checkbox>
              </el-form-item>
            </el-form>
            <el-button type="primary" class="more" @click="openMore = !openMore">
              <var class="mar-r-5 f-l">更多筛选</var>
              <i class="iconfont icon-xiangxia f-l" v-show="!openMore" />
              <i class="iconfont icon-xiangshang f-l" v-show="openMore" />
            </el-button>
            <el-form
              ref="form"
              label-width="130px"
              class="form-box"
              @submit.native.prevent
              v-show="openMore">
                <el-form-item label="内容属性">
                  <el-checkbox v-model="channels.policy" @change="changeChannels(plan)">仅政策</el-checkbox>
                </el-form-item>
                <el-form-item label="信息等级">
                    <el-radio-group v-model="plan.condition.term.msg_level.value">
                        <el-radio label="all">全部</el-radio>
                        <el-radio label="normal">精准信息</el-radio>
                        <el-radio label="garbage">噪音信息</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="媒体类型">
                  <div class="flex flex-1 mtlx">
                    <el-checkbox :indeterminate="isIndeterminate"
                      v-model="checkAll"
                      @change="handleCheckAllChange(plan)"
                      class="mar-l-20 mar-r-20"
                      :disabled="channels.policy">
                        全选</el-checkbox>
                    <el-checkbox-group class="mar-l-20"
                      v-model="plan.condition.term.source.value"
                      @change="checkboxChange(plan)"
                      :disabled="channels.policy">
                        <el-checkbox :label="option"
                          name="type"
                          :key="index"
                          v-for="(option, index) in plan.condition.term.source.option">
                        </el-checkbox>
                    </el-checkbox-group>
                  </div>
                </el-form-item>
                <el-form-item label="预警词锁定(选填)">
                  <div class="box">
                    <el-tag
                      :key="index"
                      v-for="(word, index) in focus.must"
                      closable
                      :disable-transitions="false"
                      @close="removeMust(index)">
                      {{word}}
                    </el-tag>
                    <el-input
                      class="input-new-tag"
                      v-if="must.visible"
                      v-model="must.value"
                      ref="mustInput"
                      size="small"
                      @keyup.enter.native="handleMustConfirm"
                      @blur="handleMustConfirm">
                    </el-input>
                    <el-button v-else class="button-new-tag" size="small" @click="showMustInput">+ 关键词</el-button>
                  </div>
                  <p>
                    <span class="mar-r-10">关键词关系</span>
                    <el-radio-group v-model="focus.op">
                      <el-radio label="and">与</el-radio>
                      <el-radio label="or">或</el-radio>
                    </el-radio-group>
                  </p>
                </el-form-item>
                <el-form-item label="预警词排除">
                  <div class="box">
                    <el-tag
                      :key="index"
                      v-for="(word, index) in focus.not"
                      closable
                      :disable-transitions="false"
                      @close="removeNot(index)">
                      {{ word }}
                    </el-tag>
                    <el-input
                      class="input-new-tag"
                      v-if="not.visible"
                      v-model="not.value"
                      ref="notInput"
                      size="small"
                      @keyup.enter.native="handleNotConfirm"
                      @blur="handleNotConfirm">
                    </el-input>
                    <el-button v-else class="button-new-tag" size="small" @click="showNotInput">+ 关键词</el-button>
                  </div>
                </el-form-item>
                <el-button type="primary" class="more more2" @click="openMore2 = !openMore2">
                    <i class="iconfont icon-xiangxia f-l" v-show="!openMore2" />
                    <i class="iconfont icon-xiangshang f-l" v-show="openMore2" />
                </el-button>
                <el-form
                  ref="form"
                  label-width="120px"
                  class="form-box"
                  @submit.native.prevent
                  v-show="openMore2">
                  <el-form-item label="信息等级">
                    <el-radio-group v-model="plan.condition.term.grade.value">
                      <el-radio label="all">全部</el-radio>
                      <el-radio label="important">重要</el-radio>
                      <el-radio label="super">非常重要</el-radio>
                    </el-radio-group>
                    <p class="summary" v-show="plan.condition.term.grade.value !== 'all'">（信息重要等级仅供参考，请根据方案具体情况谨慎选择）</p>
                  </el-form-item>
                </el-form>
            </el-form>
            <div class="button-group t-c">
              <el-button type="primary" round @click="save(plan)" v-loading="loading" :disabled="loading">保存修改</el-button>
              <el-button round @click="cancel(plan)">放弃修改</el-button>
            </div>
        </div>
      <!-- </vue-scroll> -->
    </div>

    <confirm ref="confirmOut" title="离开此页面" confirm="离开" @must="confirmOut" class="single-information"/>
    <contact-dialog v-on:addDone="loadContacts" ref="contact" :title="title" />
  </el-container>
</template>
<script>
  import { timeRange } from '@/utils/constants'
  import { users as customerUsers } from '@/api/customer'
  import { getContacts } from '@/api/contacts'
  import { warningNotify, warningUpdate } from '@/api/caution'
  import confirm from '@components/common/confirm.vue'
  import contactDialog from '@components/common/new-contacts.vue'
  import { mapGetters } from 'vuex'
  const _ = window._;
  export default {
    name: 'caution-drawer',
    components: {
      confirm,
      contactDialog
    },
    computed: {
      ...mapGetters([
        'user',
        'plans'
      ])
    },
    data() {
      return {
        title: null,
        yjDrawer: true,
        isIndeterminate: false,
        checkAll: false,
        users: [],
        contacts: {contacts: [], wechat: [], email: []},
        timeRange,
        timer_setting: {num: 1, one: 32400, two: ''},
        channels: {policy: false},
        focus: {must: [], not: [], op: 'and'},
        must: {visible: false, value: ''},
        not: {visible: false, value: ''},
        plan: {},
        originPlan: "",
        loading: false,
        openMore: false,
        openMore2: false,
        errMsg: {
          web: false,
          wechat: false,
          email: false
        }
      }
    },
    watch: {
      plan: {
        deep: true,
        handler: function () {
          this.checkErrMsg()
        }
      }
    },
    mounted() {
      this.loadContacts();
    },
    methods: {
      checkErrMsg() {
        this.errMsg = {
          web: false,
          wechat: false,
          email: false
        }
        const { plan } = this
        if (plan.hasOwnProperty('condition')) {
          for (let i in plan.condition.terminal.value) {
            let terminal = plan.condition.terminal.value[i]
            if (plan.condition.terminal[terminal].length >= 1) {
              this.errMsg[terminal] = false
            }
          }
        }
      },
      show(data) {
        let plan = JSON.parse(JSON.stringify(data));

        if (plan.condition.period.value === 'timer') {
          let timer = plan.condition.period.timer;
          this.timer_setting = timer.length === 2 ? {num: 2, one: timer[0], two: timer[1]} : {num: 1, one: timer[0], two: ''}
        }

        plan.condition.term.grade.value = plan.condition.term.grade.value.join(',');
        plan.condition.term.msg_level.value = plan.condition.term.msg_level.value.join(',');
        plan.condition.term.emotion.value = plan.condition.term.emotion.value.join(',');
        plan.condition.term.emotion.value = plan.condition.term.emotion.value === 'negative' ? true : false;
        this.channels.policy = plan.condition.term.channels.value.join(',') === '政务' ? true : false;
        this.focus = {
          must: plan.condition.focus.focus.must.length >= 1 ?  plan.condition.focus.focus.must.split(",") : [],
          not: plan.condition.focus.focus.not.length >= 1 ? plan.condition.focus.focus.not.split(",") : [],
          op: this.focus.op
        };
        this.plan = plan;
        this.originPlan = JSON.stringify(this.calculation(plan));
        this.checkAll = this.plan.condition.term.source.option.length === this.plan.condition.term.source.value.length;
        this.isIndeterminate = this.plan.condition.term.source.value.length > 0 && this.plan.condition.term.source.value.length < this.plan.condition.term.source.option.length;
        this.planUsers(plan);
      },
      calculation(data, verify = false) {
        const plan = JSON.parse(JSON.stringify(data));
        if (plan.condition.period.value == 'timer') {
          var timer = [];
          if (!this.timer_setting.num) {
            if (verify) this.$message.warning('请选择定时周期');
            return false;
          }
          if (this.timer_setting.one === '') {
            if (verify) this.$message.warning('请选择定时时间');
            return false;
          }
          timer.push(this.timer_setting.one);
          if (this.timer_setting.num == 2) {
            if (this.timer_setting.two === '') {
              if (verify) this.$message.warning('请选择定时时间');
              return false;
            }
            timer.push(this.timer_setting.two);
          }
          plan.condition.period.timer = timer;
        }
        if (_.size(plan.condition.term.source.value) < 1) {
          if (verify) this.$message.warning('请至少选择一个媒体来源');
          return false;
        }
        if (_.size(plan.condition.terminal.value) < 1) {
          if (verify) this.$message.warning('请选择预警推送方式');
          return false;
        }
        for (let i in plan.condition.terminal.value) {
          var terminal = plan.condition.terminal.value[i];
          if (_.size(plan.condition.terminal[terminal]) < 1) {
            this.errMsg[terminal] = true
            // if (verify) this.$message.warning(`请选择${terminal}预警推送方式联系人`);
            return false;
          }
        }
        if (plan.condition.period.value == 'presently') {
          plan.condition.terminal.value = plan.condition.terminal.value.filter(function(val) {
            return val != 'email';
          });
          plan.condition.terminal.email = [];
        }
        plan.condition.term.grade.value = plan.condition.term.grade.value.split(',');
        plan.condition.term.msg_level.value = plan.condition.term.msg_level.value.split(',');
        plan.condition.term.emotion.value = plan.condition.term.emotion.value ? ['negative'] : plan.condition.term.emotion.option;
        plan.condition.term.channels.value = this.channels.policy ? ['policy'] : ['all'];
        plan.condition.focus.value = "focus";
        plan.condition.focus.focus = {
          must: this.focus.must.join(","),
          not: this.focus.not.join(","),
          op: this.focus.op
        };

        // 状态单独设置
        delete plan.notify;

        return plan;
      },
      save(plan) {
        const sourcePlan = window._.find(this.plans, (p) => {
          return p.id === plan.plan_id
        })

        if (this.user.id !== sourcePlan.createdby && !this.user.isadmin)
        {
          this.$message.warning('您没有权限修改该方案预警信息')
          return false
        }

        const data = this.calculation(plan, true);
        if (!data) return false;
        this.loading = true;
        warningUpdate(data).then(res => {
          if (res.data.state) {
            this.$message.success('更新方案预警成功');
            this.originPlan = JSON.stringify(this.calculation(plan));
            this.$emit('done');
            this.confirmOut()
          } else {
            this.$message.error(res.data.error);
          }
          this.loading = false;
        }).catch(() => {
          this.loading = false;
          this.$message.error('保存失败，服务错误');
        });
      },
      changeState(plan) {
        const sourcePlan = window._.find(this.plans, (p) => {
          return p.id === plan.plan_id
        })

        if (this.user.id !== sourcePlan.createdby && !this.user.isadmin)
        {
          this.$message.warning('您没有权限修改该方案预警信息')
          plan.notify = plan.notify === 1 ? 0 : 1
          return false
        }


        warningNotify({
          id: plan.plan_id,
          notify: plan.notify
        }).then(res => {
          if (res.data.state) {
            this.$message.success('更新状态成功');
            this.$emit('done');
          } else {
            plan.notify = plan.notify == 0 ? 1 : 0;
            this.$message.error(res.data.error);
          }
        }).catch(() => {
          this.$message.error('更新状态失败，服务错误');
        });
      },
      handleClose(done) {
        if (JSON.stringify(this.calculation(this.plan)) !== this.originPlan) {
          this.$refs.confirmOut.show([{type: 'error', text: '若离开此页面，系统将不会保存您未保存的修改。'}])
        } else {
          done();
        }
      },
      confirmOut() {
        this.$router.push({path: `/yq?id=${this.plan.plan_id}`})
      },
      cancel(plan) {
        if (JSON.stringify(this.calculation(plan)) !== this.originPlan) {
          this.$refs.confirmOut.show([{type: 'error', text: '若离开此页面，系统将不会保存您未保存的修改。'}])
        } else {
          this.confirmOut();
        }
      },
      backRenderHtml() {
        // return (<span><span>已修改方案，</span><span class="cur-p" on-click={ () => {this.yjDrawer = false;} }>撤销</span></span>);
      },
      changeChannels(plan) {
        if (this.channels.policy) {
          plan.condition.term.source.value = ['政务'];
        } else {
          plan.condition.term.source.value = plan.condition.term.source.option;
        }
      },
      planUsers(plan) {
        customerUsers({plan_id: plan.plan_id}).then(res => {
          if (res.data.state) {
            this.users = res.data.data;
            let uids = this.users.map((item, index) => {
              return item.id;
            });
            // 过滤已删除用户ID
            plan.condition.terminal.web = plan.condition.terminal.web.filter(id => {
              return uids.indexOf(id) !== -1;
            });
          } else {
            this.$message.error(res.data.error);
          }
        }).catch(res => {
          this.$message.error('加载方案用户失败，服务错误');
        });
      },
      loadContacts() {
        const _this = this;
        getContacts().then(res => {
          if (res.data.state) {
            var email = [];
            var wechat = [];
            let result = res.data.data;
            _(result).forEach((item, i) => {
              if (item.email)
                email[email.length] = {
                  id: item.id,
                  email: item.email,
                  name: item.name,
                };
              if (item.openid)
                wechat.push({
                  id: item.id,
                  wechat: item.wechat,
                  name: item.name,
                })
            });
            _this.contacts.contacts = result;
            _this.contacts.email = email;
            _this.contacts.wechat = wechat;
          } else {
            this.$message.error(res.data.error);
          }
        }).catch(res => {
          this.$message.error('加载通讯录失败，服务错误');
        });
      },
      handleClick(tab, event) {
      },
      showMustInput() {
        if (this.focus.must.length >= 30) {
          this.$message.warning('关键词组数不得超过30组');
          return false;
        }
        this.must.visible = true;
        this.$nextTick(() => {
          this.$refs.mustInput.$refs.input.focus();
        });
      },
      handleMustConfirm() {
        let inputValue = this.must.value;
        if (inputValue) {
          this.focus.must.push(inputValue);
        }
        this.must.visible = false;
        this.must.value = '';
      },
      removeMust(index) {
        this.focus.must.splice(index, 1);
      },
      showNotInput() {
        if (this.focus.not.length >= 30) {
          this.$message.warning('关键词组数不得超过30组');
          return false;
        }
        this.not.visible = true;
        this.$nextTick(() => {
          this.$refs.notInput.$refs.input.focus();
        });
      },
      handleNotConfirm() {
        let inputValue = this.not.value;
        if (inputValue) {
          this.focus.not.push(inputValue);
        }
        this.not.visible = false;
        this.not.value = '';
      },
      removeNot(index) {
        this.focus.not.splice(index, 1);
      },
      hourFormat(hour, type) {
        var val = '';
        switch (type) {
          case 'second':
            val = parseInt(hour) * 3600;
            break;
          case 'hour':
            val = hour < 10 ? '0' + hour + ':00:00' : hour + ':00:00';
            break;
          default:
            window.console.error('format type not allowed');
        }
        return val;
      },
      // 夜间预警设置
      handleTimerangeChange(item) {
        const { value } = item;
        if (value.indexOf('push_night') > -1) {
          item.push_night = ['22:00', '08:00'];
        } else {
          item.push_night = ['', ''];
        }
      },
      wechatContactChange(plan) {
        if (plan.condition.terminal.wechat.indexOf("new_contact") !== -1) {
          this.$refs.contact.show()
          plan.condition.terminal.wechat = plan.condition.terminal.wechat.filter(function (val) {
            return val !== 'new_contact'
          })
        }
      },
      emailContactChange(plan) {
        if (plan.condition.terminal.email.indexOf("new_contact") !== -1) {
          this.$refs.contact.show()
          plan.condition.terminal.email = plan.condition.terminal.email.filter(function (val) {
            return val !== 'new_contact'
          })
        }
      },
      handleCheckAllChange(plan) {
        plan.condition.term.source.value = this.checkAll ? plan.condition.term.source.option : [];
        this.isIndeterminate = false;
      },
      checkboxChange(plan) {
        let checkedCount = plan.condition.term.source.option.length;
        plan.checkAll = checkedCount === plan.condition.term.source.value.length;
        this.isIndeterminate = plan.condition.term.source.value.length > 0 && plan.condition.term.source.value.length < checkedCount;
      },
    }
  }
</script>
<style scoped>
/* 预警设置 */
.w-800{width:800px;}
.box {padding:10px;min-height:54px;line-height: 27px;overflow: hidden;border: 1px solid #DCDFE6;border-radius: 4px;}
.setting-caution .form-box{background:none;padding:0;}
.setting-caution >>> .el-form-item,.setting-caution >>> .el-radio__label{line-height: 32px;}
.setting-caution >>> .el-form-item__label{font-weight: 600;}
.setting-caution >>> .el-radio,.setting-caution >>> .el-checkbox{margin-bottom: 10px;}
.setting-caution >>> .el-checkbox:last-of-type{margin-right: 30px;}
.setting-caution >>> .more.el-button--primary{background:none;border:none;padding:0;}
.setting-caution >>> .more.el-button--primary span{color: #333;}
/* .setting-caution >>> .button-group{text-align: left;margin-left: 50px;margin-top: 50px;} */
.setting-caution >>> .more2.el-button--primary{margin-left: 0;padding: 0;margin-top: 10px;}
.setting-caution .summary{color:#FC5D73;line-height: 20px;font-size: 12px;}
/* 迭代2.0.5 2020年6月18日15:53:24 */
p.yjts{font-weight: 600;font-size: 14px;line-height: 40px;margin-bottom:10px;}
.setting-caution .mod-title{margin-left: 0;}
.setting-caution .hr{border-bottom:1px solid #F2F2F2;overflow: hidden;margin:10px 0;}
.mtlx >>> .el-checkbox{margin-bottom:0;}
.err-text {font-size: 12px;color: red;margin-left: 10px;}
</style>
