<template>
  <el-container>
    <div class="w-100 caution-index">
      <div class="content">
        <el-card class="box-card min-h-600">
          <div class="shadow w-100 ov-h">
            <div class="mod-title" v-if="cautionPlan">
              {{ cautionPlan.name }}
            </div>
          </div>
          <el-tabs v-model="tabIndex">
            <el-tab-pane label="设置预警" name="setting">
              <setting ref="setting" v-if="tabIndex === 'setting'" />
            </el-tab-pane>
            <el-tab-pane label="预警列表" name="list">
              <caution-list ref="list" v-if="tabIndex === 'list'" />
            </el-tab-pane>
          </el-tabs>
        </el-card>
        <!-- <el-card class="box-card"> -->

        <!-- </el-card> -->
      </div>
    </div>
  </el-container>
</template>
<script type="application/javascript">
import setting from './items/setting';
import cautionList from './items/plan-list';
import { warningList } from '@/api/caution';
const { _ } = window;
export default {
  name: 'plan-caution',
  data() {
    return {
      tabIndex: 'setting',
      cautionListLoading: false,
      cautionList: [],
      pid: null,
      cautionPlan: null
    };
  },
  components: {
    setting,
    'caution-list': cautionList
  },
  watch: {
    tabIndex: function(index) {
      if (index === 'setting') {
        this.$nextTick(() => {
          if (this.cautionPlan) {
            this.$refs.setting.show(this.cautionPlan);
          }
        });
      } else if (index === 'list') {
        this.$nextTick(() => {
          if (this.cautionPlan) {
            this.$refs.list.plan = this.cautionPlan;
            this.$refs.list.loadData();
          }
        });
      }
    }
  },
  mounted() {
    if (this.$route.params.hasOwnProperty('pid')) {
      this.pid = this.$route.params.pid;

      this.$nextTick(() => {
        this.loadCautionList();
      });
    } else {
      this.$router.push({ name: 'yq' });
    }
  },
  methods: {
    loadCautionList() {
      this.cautionListLoading = true;
      warningList({ enable: 'all', type: 'expr' })
        .then(res => {
          if (res.data.state) {
            this.cautionList = res.data.data;
            this.cautionListLoading = false;

            const cautionPlan = _.find(this.cautionList, plan => {
              return plan.plan_id === this.pid;
            });
            if (cautionPlan === undefined) {
              this.$message.warning('找不到该方案预警');
              return false;
            }

            this.cautionPlan = cautionPlan;
            if (this.tabIndex === 'setting') {
              this.$refs.setting.show(cautionPlan);
            }
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(err => {
          this.$message.error(err);
        });
    }
  }
};
</script>
<style scoped>
.caution-index >>> .el-tabs--top > .el-tabs__header {
  border-bottom: 1px solid #f2f2f2;
  margin-top: 0;
}
.caution-index >>> .el-tabs--top > .el-tabs__content {
  box-shadow: none;
  padding-bottom: 30px;
}
.caution-index >>> .el-card__body {
  padding: 0;
}
.caution-index .shadow {
  height: 40px;
  box-shadow: 0px 2px 20px 0px rgba(65, 70, 76, 0.07);
  padding-left: 20px;
  padding-top: 24px;
}
</style>
